import * as React from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import Layout from '../layouts/Layout'
import InsightView from '../components/Insights/InsightView'
import backgroundImage from './images/brazil-original.jpg'

const VideoWrapper = styled.div`
  div.vjs-text-track-display {
    background: transparent;
  }
  div.mp-component_azure-mp-container__idgeq {
    background: transparent;
  }
  div.azuremediaplayer {
    background: transparent;
  }
  div.vjs-player {
    background:url("${backgroundImage}") no-repeat center;
    background-size: cover;
  }
  video {
    display: none;
  }
`

const MarketUpdate = () => {
  const data = {
    videoURL: 'https://tfproductionmediaservice-euwe.streaming.media.azure.net/1f0f8fe3-2341-496b-82f1-8078cbd9ea15/Emso Market Update - 27 March 20.ism/manifest',
    title: 'Market Update - 27 March 2020',
    standalone: true,
    authorName: 'Ben Sarano, Patrick Esteruelas, Donald Lucardi',
    summary: 'Emso’s Global Head of Investor Relations, Don Lucardi, had a conversation with our Global Head of Research, Patrick Esteruelas, and Portfolio Manager Ben Sarano to discuss the latest updates on the coronavirus pandemic and the volatile market movements over  the last few days. The recording of their conversation is available below. Emso politely asks that you not share this link or recording as it was recorded solely for our investors.',
  }
  return (
    <Layout>
      <Helmet>
        <title>Market Update - 23 March 2020 - Emso </title>
        <meta property="og:title" content="Market Update - 27 March 2020 - EMSO"/>
      </Helmet>

      <VideoWrapper>
        <InsightView insight={data}/>
      </VideoWrapper>

    </Layout>
  )
}

export default MarketUpdate
